@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Lato', 'sans-serif';
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 5px;
}